/*
  Extended from femtoJS https://vladocar.github.io/femtoJS/

  This utils must not have anything other than DOM Manipulation
*/
class DOM {
  constructor(selector, parent = document) {
    let elements;
    if (selector instanceof Element) {
      elements = [selector];
    } else if (typeof selector === "string") {
      const tagName = /^<(\w+)>$/.exec(selector);

      if (tagName !== null) {
        elements = [document.createElement(tagName[1])];
      } else {
        elements = [...document.querySelectorAll(selector)];
      }
    } else if (selector instanceof NodeList) {
      elements = selector;
    } else if (selector[Symbol.iterator]) {
      elements = [];
      for (const el of selector) {
        elements.push(...(el instanceof DOM ? el : [el]));
      }
    } else if (selector.matches) {
      elements = parent.querySelectorAll(selector);
    } else {
      throw new Error("Invalid selector", selector);
    }

    // Store the length property and assign elements to the current instance
    this.length = elements.length;
    for (let i = 0; i < this.length; i++) {
      this[i] = elements[i];
    }

    // Enable iterator on the current instance
    this[Symbol.iterator] = elements[Symbol.iterator].bind(elements);
  }

  // Method to get or set attributes
  attr(attrName, value) {
    if (value === undefined) {
      return this[0].getAttribute(attrName);
    } else {
      for (let i = 0; i < this.length; i++) {
        this[i].setAttribute(attrName, value);
      }
      return this;
    }
  }

  // Method to find an element by attribute
  findByAttr(attr, value) {
    const root = this && this[0] ? this[0] : document.body;
    if (root.hasAttribute(attr) && root.getAttribute(attr) == value) {
      return root;
    }
    let children = root.children;
    let element;
    for (let i = children.length; i--; ) {
      element = _$(children[i]).findByAttr(attr, value);
      if (element) {
        return element;
      }
    }
    return null;
  }

  // Method to get or set properties
  prop(propName, value) {
    if (value === undefined && this[0]) {
      return this[0][propName];
    } else {
      for (let i = 0; i < this.length; i++) {
        this[i][propName] = value;
      }
      return this;
    }
  }

  // Method to hide elements
  hide() {
    for (let i = 0; i < this.length; i++) {
      this[i].style.display = "none";
    }
    return this;
  }

  // Method to show elements
  show() {
    for (let i = 0; i < this.length; i++) {
      this[i].style.display = "";
      this[i].classList.remove("is-hidden");
    }
    return this;
  }

  // Method to get or set input values
  val(val) {
    if (val !== undefined) {
      for (let i = 0; i < this.length; i++) {
        this[i].value = val;
      }
      return this;
    } else {
      let result = "";
      for (let i = 0; i < this.length; i++) {
        result += this[i].value;
      }
      return result;
    }
  }

  // Method to get or set text content
  text(text) {
    if (text !== undefined) {
      for (let i = 0; i < this.length; i++) {
        this[i].textContent = text;
      }
      return this;
    } else {
      let result = "";
      for (let i = 0; i < this.length; i++) {
        result += this[i].textContent;
      }
      return result;
    }
  }

  // Method to get or set HTML content
  html(html) {
    if (html !== undefined) {
      for (let i = 0; i < this.length; i++) {
        this[i].innerHTML = html;
      }
      return this;
    } else {
      let result = "";
      for (let i = 0; i < this.length; i++) {
        result += this[i].innerHTML;
      }
      return result;
    }
  }

  // Method to empty the elements
  empty() {
    this.html("");
    return this;
  }

  // Method to apply CSS styles
  css(property, value, css) {
    if (typeof css === "string") {
      this.each((i) => (i.style.cssText += css));
      const style = this.prop("style");
      return typeof style !== "undefined" ? this : style && style.cssText;
    }
    if (value !== undefined) {
      for (let i = 0; i < this.length; i++) {
        this[i].style[property] = value;
      }
      return this;
    } else {
      if (typeof property === "string") {
        return this[0].style[property];
      } else {
        for (let i = 0; i < this.length; i++) {
          for (let prop in property) {
            this[i].style[prop] = property[prop];
          }
        }
        return this;
      }
    }
  }

  // Method to add a CSS class
  addClass(className) {
    for (let i = 0; i < this.length; i++) {
      this[i].classList.add(className);
    }
    return this;
  }

  // Method to remove a CSS class
  removeClass(className) {
    for (let i = 0; i < this.length; i++) {
      this[i].classList.remove(className);
    }
    return this;
  }

  // Method to toggle a CSS class
  toggleClass(className, condition) {
    for (let i = 0; i < this.length; i++) {
      this[i].classList.toggle(className, condition);
    }
    return this;
  }

  // Method to iterate over elements
  each(callback) {
    for (let i = 0; i < this.length; i++) {
      callback(this[i], i);
    }
    return this;
  }

  // Method to attach an event listener
  on(eventName, handler) {
    for (let i = 0; i < this.length; i++) {
      this[i].addEventListener(eventName, handler);
    }
    return this;
  }

  // Method to remove an event listener
  off(eventName, handler) {
    for (let i = 0; i < this.length; i++) {
      this[i].removeEventListener(eventName, handler);
    }
    return this;
  }

  // Method to append content
  append(content) {
    for (let i = 0; i < this.length; i++) {
      if (typeof content !== "string") {
        this[i].insertAdjacentElement("beforeend", content);
      } else {
        this[i].insertAdjacentHTML("beforeend", content);
      }
    }
    return this;
  }

  // Method to prepend content
  prepend(content) {
    for (let i = 0; i < this.length; i++) {
      if (typeof content !== "string") {
        this[i].insertAdjacentElement("afterbegin", content);
      } else {
        this[i].insertAdjacentHTML("afterbegin", content);
      }
    }
    return this;
  }

  // Method to insert content after each element
  after(content) {
    for (let i = 0; i < this.length; i++) {
      if (typeof content !== "string") {
        this[i].insertAdjacentElement("afterend", content);
      } else {
        this[i].insertAdjacentHTML("afterend", content);
      }
    }
    return this;
  }

  // Method to insert content before each element
  before(content) {
    for (let i = 0; i < this.length; i++) {
      if (typeof content !== "string") {
        this[i].insertAdjacentElement("beforebegin", content);
      } else {
        this[i].insertAdjacentHTML("beforebegin", content);
      }
    }
    return this;
  }

  // Method to get the parent elements
  parent() {
    const parentElems = [];
    for (let i = 0; i < this.length; i++) {
      if (!parentElems.includes(this[i].parentNode)) {
        parentElems.push(this[i].parentNode);
      }
    }
    return new DOM(parentElems);
  }

  // Method to find elements within the current elements
  find(selector, first) {
    if (first) {
      return this[0].querySelector(selector);
    }

    const foundElems = [];

    for (let i = 0; i < this.length; i++) {
      const elems = this[i].querySelectorAll(selector);
      for (let j = 0; j < elems.length; j++) {
        if (!foundElems.includes(elems[j])) {
          foundElems.push(elems[j]);
        }
      }
    }

    return new DOM(foundElems);
  }

  // closest(selector) {
  //   const foundElems = [];
  //   for (let i = 0; i < this.length; i++) {
  //     const elems = this[i].closest(selector);
  //     for (let j = 0; j < elems.length; j++) {
  //       if (!foundElems.includes(elems[j])) {
  //         foundElems.push(elems[j]);
  //       }
  //     }
  //   }
  //   return new DOM(foundElems);
  // }

  // Method to get the offset position of the first element
  offset() {
    const rect = this[0].getBoundingClientRect();
    return {
      top: rect.top + window.pageYOffset,
      left: rect.left + window.pageXOffset,
    };
  }

  // Method to remove the elements from the DOM
  remove() {
    for (let i = 0; i < this.length; i++) {
      this[i].parentNode.removeChild(this[i]);
    }
    return this;
  }

  // Method to check if the first element matches a selector
  is(selector) {
    return this[0].matches(selector);
  }

  // Method to get the current element or its closest parent that matches a selector
  selfOrParent(selector) {
    if (this.is(selector)) {
      return new DOM(this[0]);
    } else {
      return this[0].closest(selector);
    }
  }

  // Method to filter out elements that don't match a selector
  not(selector) {
    const filteredElems = [];
    for (let i = 0; i < this.length; i++) {
      if (!this[i].matches(selector)) {
        filteredElems.push(this[i]);
      }
    }
    return new DOM(filteredElems);
  }

  // Method to filter elements that match a selector
  filter(selector) {
    const filteredElems = [];
    for (let i = 0; i < this.length; i++) {
      if (this[i].matches(selector)) {
        filteredElems.push(this[i]);
      }
    }
    return new DOM(filteredElems);
  }

  // Method to get the first element
  first() {
    return new DOM(this[0]);
  }

  // Method to get the last element
  last() {
    return new DOM(this[this.length - 1]);
  }

  // Method to get the nth element
  eq(index) {
    return new DOM(this[index]);
  }

  slice(start, end) {
    return new DOM(Array.from(this).slice(start, end));
  }

  ready(callback) {
    document.addEventListener("DOMContentLoaded", callback);
  }

  el() {
    return this[0];
  }

  sel() {
    return [...this];
  }

  hasClass(t) {
    return this && this.length > 0 ? this[0].classList.contains(t) : false;
  }
}

// Additional selectors
DOM.expr = {
  visible: (elem) => {
    return !!(
      elem.offsetWidth ||
      elem.offsetHeight ||
      elem.getClientRects().length
    );
  },
};

DOM.fn = DOM.prototype;

export const _$ = (selector) => {
  return new DOM(selector);
};
